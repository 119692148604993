const appConfig = {
  apiPrefix: "https://api.cleocards.com/api/v1/admin",
  // apiPrefix: "http://localhost:4000/api/v1/admin",
  authenticatedEntryPath: "/cards",
  unAuthenticatedEntryPath: "/sign-in",
  tourPath: "/",
  locale: "en"
};

export default appConfig;
